import React from "react";
import Layout from "../../../components/Layout";
import OwnedEventView from "../../../components/OwnedEventView";

type EditEventParams = {
  params: {
    id: string
  }
}

export default ({ params }: EditEventParams) => {
  const { id } = params;
  return (
    <Layout>
      <OwnedEventView id={id} />
    </Layout>
  )
}
