import React, {useCallback} from 'react';
import { Box, Card, Heading, Image, Text } from 'theme-ui';
import Helmet from "react-helmet";
import { endpoint } from '../lib/endpoint'
import { navigate } from 'gatsby';
import ActionButton from './ActionButton';
import AuthenticatedDataView from './AuthenticatedDataView';
import InlineShareButton from './InlineShareButton';
import ReactMarkdown from 'react-markdown';
import linkUrl from '../lib/linkUrl';
import MyEventsBreadcrumbs from './MyEventsBreadcrumbs';
import OwnedEventListedStatusIndication from './OwnedEventListedStatusIndication';
import EventDateAndTime from './EventDateAndTime';
import {jsonToEvent} from '../types';

type OwnedEventViewProps = {
  id: string
};

const OwnedEventDataView = ({ data }) => {
  const event = jsonToEvent(data.event);
  const participants = data.participants;

  const subject = `Message from your Organizer: ${event.title}`;
  const body = `Hello\n(fill in message content here)\n\nEvent: ${global.location.protocol}//${global.location.host}/events/${event.guid}`;

  const messageAll = useCallback(() => {
    const emails = participants.map(p => p.email);
    global.location.href = `mailto:?bcc=${encodeURIComponent(emails.join(","))}`
      + `&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  }, [body, subject, event]);

  return <>
    <Card sx={{ pt: 2 }}>
      <Helmet title={event.title} />
      <MyEventsBreadcrumbs />
      { event.image_card_url &&
        <Box className="event-image">
          <Image src={event.image_card_url} variant="eventCard" />
        </Box>
      }
      <Heading>
        {event.title}
	<InlineShareButton
	  title={event.title}
	  text={`Invitation: ${event.title}`}
	  url={`${linkUrl}/events/${event.guid}`}
	  onClick={() => console.log("shared")} />
      </Heading>
      <EventDateAndTime event={event} />
      {/* event.image_thumb_url &&
        <Box pt={2} pb={2}>
          <Image src={event.image_thumb_url} variant="eventThumb" />
        </Box>
      */}
      { event.description &&
        <Text>
          <ReactMarkdown>
            {event.description}
          </ReactMarkdown>
        </Text>
      }
      <Box pt={2} pb={2}>
        <ActionButton isValid={true} onClick={() => navigate(`/owned-events/edit/${event.id}`)}>
          Edit
        </ActionButton>
      </Box>
      <OwnedEventListedStatusIndication event={event} />
      <Heading pt={4}>
        Your Participants
      </Heading>
      { participants.length < 1 &&
        <Text>You don't have any participants yet. Push "Share" above, to share the event with your networks.</Text>
      }
      { participants.length > 0 &&
        <>
          <Box pb={2}>
            <ActionButton isValid={true} onClick={messageAll}>
              Message All Participants
            </ActionButton>
          </Box>
          <Box>
            <Text>{participants.length} participants have registered:</Text>
          </Box>
          { participants.map(p => {
              return <Card key={p.id}>
                <Box pb={2} >
                  <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>{p.name}</Text>
                  <Text> (<a href={`mailto:${p.email}?&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`}>{p.email}</a>)</Text>
                </Box>
              </Card>
          })}
        </>
      }
    </Card>
    {/* <div>{JSON.stringify(event)}</div> */}
  </>;
};

export default ({ id }: OwnedEventViewProps) => {
  console.log('render view, eventId', id);
  return (
    <AuthenticatedDataView fetchUrl={`${endpoint}/owned-events/${id}`}
      render={data => <>
        <OwnedEventDataView data={data} />
      </>}
    />
  );
}
